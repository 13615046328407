import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import { colors } from "../colors/colors"

const NavElement = styled.nav`
  grid-column: 5/6;
  grid-row: 1/3;

  @media (max-width: 811px) {
    width: 100%;
  }
`

const NavUl = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: row;
  padding: 0;

  @media (max-width: 811px) {
    justify-content: space-evenly;
  }
`
const NavLi = styled.li`
  margin: 20px;

  @media (max-width: 811px) {
    margin: 10px;
  }
`
const NavLinks = styled(Link)`
  display: inline-block;
  color: ${colors.white};
  text-decoration: none;
  font-size: 22px;
  font-style: italic;

  &:hover {
    color: ${colors.brightRed};
  }

  @media (max-width: 811px) {
    font-size: 16px;
  }
`
const sitePaths = [
  { path: "/projects", name: "Projects" },
  { path: "/contact", name: "Contact" },
  { path: "/about", name: "About StraightEIGHT" },
]

const SiteNav = () => (
  <NavElement>
    <NavUl>
      {sitePaths.map((link, i) => (
        <NavLi key={i}>
          <NavLinks
            exact="true"
            to={link.path}
            activeStyle={{ color: `${colors.brightRed}` }}
          >
            {link.name}
          </NavLinks>
        </NavLi>
      ))}
    </NavUl>
  </NavElement>
)

export default SiteNav
