import React from "react"
import styled from "styled-components"
import { FaFacebookF, FaTwitter, FaInstagram } from "react-icons/fa"
import { colors } from "../colors/colors"
import "./socialContainer.css"

const SocialContainer = styled.div`
  grid-column: 2/3;
  display: flex;
  justify-self: end;
  justify-content: space-evenly;
  width: 100%;

  &:hover a {
    ${colors.brightRed};
  }
  @media (max-width: 1000px) {
    width: 100%;
  }

  @media (max-width: 811px) {
    width: 100%;
  }
`

const Links = styled.a`
  display: inline-block;
`

const SocialIcons = () => (
  <SocialContainer>
    <Links
      target="_blank"
      rel="noopener noreferrer"
      href="https://www.instagram.com/straighteightfilms/"
      className="hover-icon"
    >
      <FaInstagram size="32" />
    </Links>
    <Links
      target="_blank"
      rel="noopener noreferrer"
      href="https://www.facebook.com/StraightEIGHTFilms/"
      className="hover-icon"
    >
      <FaFacebookF size="32" />
    </Links>
    <Links
      target="_blank"
      rel="noopener noreferrer"
      href="https://twitter.com/matthewjclark"
      className="hover-icon"
    >
      <FaTwitter size="32" />
    </Links>
  </SocialContainer>
)

export default SocialIcons
