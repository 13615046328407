import React from "react"
import styled from "styled-components"
import SocialContainer from "./socialContainer"

const Footer = styled.div`
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
  grid-area: 3/1/4/2;
  display: grid;
  grid-template-columns: 1fr 300px;
  z-index: 99;

  @media (max-width: 1000px) {
    display: flex;
    width: 100%;
  }

  @media (max-width: 811px) {
    width: 100%;
    display: flex;
    justify-content: center;
  }
`

const SocialFooter = () => {
  return (
    <Footer>
      <SocialContainer />
    </Footer>
  )
}

export default SocialFooter
