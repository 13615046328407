import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import SiteNav from "./nav"
import HeaderLogo from "../SVGs/headerLogo"

const SiteHeader = styled.div`
  width: 100%;
  height: 125px;
  padding: 10px;
  background: transparent;
  z-index: 100;
  grid-area: 1 / 1 / 2 / 2;
`

const HeaderContainer = styled.div`
  display: grid;
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 50px 50px;
  align-items: center;

  @media (max-width: 811px) {
    display: flex;
    height: auto;
    flex-flow: wrap column;
  }
`
const HeaderLogoHolder = styled.div`
  height: 75px;
  width: 75px;
  grid-column: 1/1;
  grid-row: 1/3;
`

const MainHeader = ({ siteTitle }) => (
  <SiteHeader>
    <HeaderContainer>
      <HeaderLogoHolder style={{ margin: 0 }}>
        <Link to="/home">
          <HeaderLogo />
        </Link>
      </HeaderLogoHolder>
      <SiteNav />
    </HeaderContainer>
  </SiteHeader>
)

MainHeader.propTypes = {
  siteTitle: PropTypes.string,
}

MainHeader.defaultProps = {
  siteTitle: ``,
}

export default MainHeader
