import React from "react"
import styled from "styled-components"
import BlockContent from "@sanity/block-content-to-react"
import MainHeader from "../components/header-components/mainHeader"
import Layout from "../components/layout"
import SEO from "../components/seo"
import TestBg from "../../content/assets/testcontactbg.jpg"
import { colors } from "../components/colors/colors"
import ContactForm from "../components/contactForm"
import SocialFooter from "../components/socialBox/socialFooter"
import { graphql } from "gatsby"

const ContactContainer = styled.section`
  min-height: 100vh;
  max-height: 100%;
  display: grid;
  grid-template-rows: 125px 1fr 75px;
  grid-template-columns: 1fr;
  background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url("${TestBg}");
  background-size: cover;
  background-repeat: no-repeat;
`
const Container = styled.div`
  width: 100%;
  max-width: 1300px;
  grid-row: 2/3;
  display: flex;
  flex-flow: wrap column;
  margin: 0 auto;
  @media (max-width: 811px) {
    margin: 40px 0 30px 0;
  }
`

const AddressHolder = styled.div`
  align-self: flex-start;
  padding: 10px;
  color: ${colors.white};
  font-size: 18px;
  line-height: 1.25em;
  @media (max-width: 811px) {
    padding: 0;
    align-self: center;
    width: 90%;
    margin: 0 auto;
  }
`

const ContactPage = ({ data }) => {
  const address = data.sanityPage._rawPageText

  return (
    <Layout>
      <SEO title="Contact" />
      <ContactContainer>
        <MainHeader />
        <Container>
          <AddressHolder>
            <BlockContent blocks={address} />
          </AddressHolder>
          <ContactForm />
        </Container>
        <SocialFooter />
      </ContactContainer>
    </Layout>
  )
}

export default ContactPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    sanityPage(title: { eq: "Contact Page" }) {
      _rawPageText
    }
  }
`
