import React from "react"
import styled from "styled-components"

const STZero = styled.path`
  opacity: 0.7;
  fill: #ffffff;
`

const STOne = styled.path`
  fill: #ffffff;
`
const Graphic = styled.svg`
  height: 100%;
  width: 100%;
`

const HeaderLogo = () => (
  <Graphic
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 117 162.9"
  >
    <g>
      <STZero
        d="M108.7,50.6c0.1-0.9-0.6-1.8-1.6-1.8H107H74c-0.6,0-1.2,0.4-1.5,0.9v0.1c-2.3,4.3-7.5,7.5-14.2,10.7
		c10.2,4.5,23.1,9,34.1,15.8C94.4,74.8,107.4,66.7,108.7,50.6z"
      />
      <STZero
        d="M20.7,78.3C16.5,81.1,2.9,91.1,1.5,107c-0.1,0.9,0.6,1.8,1.5,1.9c0.1,0,0.1,0,0.2,0h34.9
		c0.6,0,1.2-0.4,1.5-0.9l0,0c0,0,0-0.1,0.1-0.1c2.1-5.3,8-9.3,15.5-13l0,0C44.2,90.3,31,86,20.7,78.3z"
      />
      <STOne
        d="M92.5,76.3C92.5,76.3,92.5,76.3,92.5,76.3c-11-6.8-34.1-15.9-34.1-15.9c-10.5-4.5-18.2-9.2-18.2-16.5
		c0-8.1,7-14.2,17.6-14.2c8,0,13.4,4.5,15.3,10.4c0.3,0.8,1,1.4,1.9,1.4h31.8c1.2,0,2.1-1,2-2.2C107,17.1,93.5,0.3,57,0.3
		c-37.2,0-52.8,20-52.8,44.2c0,16.3,7,26.5,16.6,33.7c0,0,0.1,0,0.1,0c-0.1,0-0.2,0.1-0.2,0.1C31,86,44.2,90.3,55.2,94.8
		c11.8,4.8,20.9,9.8,20.9,19.6c0,8.5-6.3,16.3-19.1,16.3c-10.9,0-16.7-6.1-18.2-13.4c-0.2-0.9-1-1.6-2-1.6H3.3c-1.2,0-2.1,1-2,2.2
		c1.7,22.5,16.3,44.8,56.5,44.8c43.3,0,58-26.4,58-48.2C115.9,95.7,105.5,84.3,92.5,76.3z"
      />
    </g>
  </Graphic>
)

export default HeaderLogo
