import React from "react"
import styled from "styled-components"
import { colors } from "./colors/colors"
import { navigate } from "gatsby"

const FormShell = styled.form`
  margin-bottom: 50px;
  align-self: flex-start;
  display: flex;
  flex-flow: wrap column;
  @media (max-width: 811px) {
    align-items: center;
  }
`
const FormFields = styled.div`
  width: 100%;
  @media (max-width: 811px) {
    width: 90%;
  }
`
const Labels = styled.label`
  font-size: 22px;
  color: ${colors.white};
  display: block;
  font-style: italic;
  margin: 10px 0 5px 10px;
`
const Inputs = styled.input`
  font-size: 14px;
  margin: 10px 0 5px 10px;
  padding: 5px;
  border-radius: 6px 6px 6px 5px;
  @media (max-width: 811px) {
    width: 100%;
    margin: 0;
  }
`

const MessageText = styled.textarea`
  resize: none;
  margin: 10px 0 5px 10px;
  padding: 5px;
  font-size: 14px;
  border-radius: 6px 6px 6px 5px;
  @media (max-width: 811px) {
    margin: 0;
    width: 100%;
  }
`
const SubmitButton = styled.button`
  margin: 10px;
  height: 30px;
  width: 20%;
  font-size: 22px;
  border-radius: 6px 6px 6px 5px;
  @media (max-width: 811px) {
    width: 100%;
    margin: 30px 0 10px 0;
  }
`

const ActiveButton = styled(SubmitButton)`
  background: ${colors.mintBlue};
  color: ${colors.white};
  border: 0;
  &:hover {
    background: #319b8b;
  }
`

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

export default function ContactForm() {
  const [state, setState] = React.useState({})

  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch(error => alert(error))
  }
  return (
    <FormShell
      name="contact"
      method="post"
      action="/response/"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      onSubmit={handleSubmit}
    >
      <input type="hidden" name="form-name" value="contact" />
      <p hidden>
        <label>
          Don’t fill this out:{" "}
          <input name="bot-field" onChange={handleChange} />
        </label>
      </p>
      <FormFields>
        <Labels htmlFor="email">Email:</Labels>
        <Inputs
          type="email"
          name="email"
          placeholder="you@emailprovider.com"
          onChange={handleChange}
          required
        />
      </FormFields>
      <FormFields>
        <Labels htmlFor="message">Message:</Labels>
        <MessageText
          cols="60"
          rows="20"
          name="message"
          type="text"
          placeholder="your message here"
          onChange={handleChange}
        />
      </FormFields>
      <FormFields>
        <ActiveButton type="submit">Send</ActiveButton>
      </FormFields>
    </FormShell>
  )
}
